<template>
  <section>
    <div>
      <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef" @doCollapseEffect="getSize">
        <template #customform>
          <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
            <a-form-item label="Org" name="org" class="rms-form-item">
              <a-input
                  v-model:value="formState.org"
                  placeholder="请输入"
                  enter-button
                  allowClear
                  autoComplete="off"
              />
            </a-form-item>
            <a-form-item label="Team" name="team" class="rms-form-item">
              <a-input
                  v-model:value="formState.team"
                  placeholder="请输入"
                  enter-button
                  allowClear
                  autoComplete="off"
              />
            </a-form-item>
            <a-form-item label="Resource Manager " name="rmNameAndGgid" class="rms-form-item">
              <a-input
                  v-model:value="formState.rmNameAndGgid"
                  placeholder="Name/GGID"
                  enter-button
                  allowClear
                  autoComplete="off"
              />
            </a-form-item>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <a-button
                type="primary"
                @click="newRmClick"
            >
              <PlusOutlined />
              新建
            </a-button>
          </div>
        </template>
      </FilterLayout>
    </div>
    <div>
      <a-table ref="tableRef" :columns="columns" :data-source="tableData" rowKey="id"
               :scroll="{ y: tableHeight, x: 1000 }" :pagination="pagination">
        <template #action="{ record }">
          <ActionBtn v-bind="record" :btnList="btnList" @FormOutlined="editClick(record)" @DeleteOutlined="deleteClick(record)" />
        </template>
      </a-table>
    </div>
    <RmListModal :visible="rmListVisible" :formState="rmFormState" @close-modal="closeModal" @save-modal="saveClick"></RmListModal>
    <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" title="正在删除"/>
  </section>
</template>

<script>
import {defineComponent, nextTick, onMounted, reactive, ref} from "vue";
import {PlusOutlined} from "@ant-design/icons-vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {cloneDeep} from "lodash";
import {useTableHeight} from "@/utils/common";
import ActionBtn from "@/components/ActionBtn.vue";
import ModalBox from "@/components/ModalBox.vue";
import RmListModal from "@/views/home/ConfigManagement/EmailManagement/RmManagementTab/components/RmListModal.vue";
import {message} from "ant-design-vue";
const btnList = [
  {
    name: '编辑',
    icon: 'FormOutlined',
    auth: []
  },
  {
    name: '删除',
    icon: 'DeleteOutlined',
    auth: []
  }
]
export default defineComponent({
  name: "index",
  components: {RmListModal, ModalBox, ActionBtn, PlusOutlined},
  setup() {
    let initData = {
      org: '',
      team: '',
      rmNameAndGgid: '',
    }
    const { $api } = useGlobalPropertyHook();
    const formState = reactive({...initData});
    const formRef = ref();
    let currentState = {}
    const tableHeight = ref(0);
    const tableData = ref([])
    const typeOps = ref([])

    const resetForm = () => {
      Object.assign(formState,initData)
      formRef.value.resetFields();
      updateParams()
      doSearch();
    };

    const updateParams =() => {
      currentState = cloneDeep(formState);
    }

    const doSearch = () => {
      pagination.current = 1;
      updateParams()
      getTableData(currentState)
    };
    // const resetSearch = () => {
    //   updateParams()
    //   getTableData(currentState)
    // };

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    const columns = [
      {
        title: "Org",
        dataIndex: "org",
        key: "org",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Team",
        dataIndex: "team",
        key: "team",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Resource Manager",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ellipsis: true,
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        slots: { customRender: "action" },
        width: 100,
        fixed: "right",
        align: "center",
      },
    ]

    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getTableData(currentState);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        getTableData(currentState);
      },
    });

    const getTableData = (filterData) => {
      const params = {
        ...filterData,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getRmList(params).then((res) => {
        pagination.total = res.data.total;
        tableData.value = res.data.resource;
      });
    };

    const deleteRecord = ref("")
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const deleteClick = (record) => {
      deleteRecord.value = record
      content.value = '是否确认删除？'
      showIconCom.value = ''
      modelValue.value = true
    }
    const handleOk = () => {
      $api.delRmList({id:deleteRecord.value.id}).then((res) => {
        if(res.code === 200){
          message.success(res.msg);
          modelValue.value = false
          doSearch();
        }
      });
    }

    const rmListVisible = ref(false)
    const rmFormState = ref()
    const editClick = (record) => {
      rmFormState.value = record
      rmListVisible.value = true
    }
    const newRmClick = () => {
      rmFormState.value = {}
      rmListVisible.value = true
    }
    const closeModal = () => {
      rmListVisible.value = false
    }
    const saveClick = () => {
      rmListVisible.value = false
      doSearch();
    }


    onMounted(() => {
      doSearch();
      getSize();
    });
    return {
      formState,
      formRef,
      resetForm,
      doSearch,
      tableHeight,
      getSize,
      columns,
      tableData,
      pagination,
      getTableData,
      typeOps,
      deleteClick,
      editClick,
      newRmClick,
      btnList,
      modelValue,
      content,
      showIconCom,
      deleteRecord,
      handleOk,
      rmListVisible,
      closeModal,
      saveClick,
      rmFormState,
    };
  }
})
</script>

<style scoped lang="less">

</style>
