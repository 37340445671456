<template>
  <section>
    <div>
     <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef" @doCollapseEffect="getSize">
       <template #customform>
         <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
           <a-form-item label="邮件类型" name="emailTypeList" class="rms-form-item">
             <SelectWithAll
                 v-model:value="formState.emailTypeList"
                 placeholder="请选择"
                 mode="multiple"
                 showArrow
                 allowClear
                 :maxTagCount="1"
                 :options="typeOps"
             >
             </SelectWithAll>
           </a-form-item>
         </a-form>
       </template>
     </FilterLayout>
    </div>
    <div>
      <a-table ref="tableRef" :columns="columns" :data-source="tableData" rowKey="id"
               :scroll="{ y: tableHeight, x: 1000 }" :pagination="pagination">
        <template #action="{ record }">
          <a-space>
            <Tooltip title="配置">
              <SettingOutlined @click="configClick(record)" class="icon"/>
            </Tooltip>
            <Tooltip title="RM 列表">
              <CheckCircleOutlined @click="checkClick(record)" class="icon" v-if="record.isCheck === 1"/>
              <img src="@/assets/images/check.png" @click="checkClick(record)" alt="" class="img" v-else/>
            </Tooltip>
<!--            <Tooltip title="停用">-->
<!--              <MinusCircleOutlined @click="deactivateClick(record)" class="icon redIcon"/>-->
<!--            </Tooltip>-->
          </a-space>
        </template>
      </a-table>
    </div>
    <ConfigModal :visible="configVisible" :infos="infos" @close-modal="closeModal" ></ConfigModal>
    <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" title="正在停用"/>
  </section>
</template>

<script>

import {defineComponent, nextTick, onMounted, reactive, ref} from "vue";
import {CheckCircleOutlined,SettingOutlined} from "@ant-design/icons-vue";
import Tooltip from "@/components/Tooltip.vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {cloneDeep} from "lodash";
import {dictionaryOpt, useTableHeight} from "@/utils/common";
import ModalBox from "@/components/ModalBox.vue";
import ConfigModal from "@/views/home/ConfigManagement/EmailManagement/EmailManagementTab/components/ConfigModal.vue";
import {message} from "ant-design-vue";

export default defineComponent({
  name: "index",
  components: {ConfigModal, ModalBox, Tooltip, CheckCircleOutlined,SettingOutlined},
  setup() {
    let initData = {
      emailTypeList: [],
    }
    const { $api } = useGlobalPropertyHook();
    const formState = reactive({...initData});
    const formRef = ref();
    let currentState = {}
    const tableHeight = ref(0);
    const tableData = ref([])
    const typeOps = ref([])

    const resetForm = () => {
      Object.assign(formState,initData)
      formRef.value.resetFields();
      updateParams()
      doSearch();
    };

    const updateParams =() => {
      currentState = cloneDeep(formState);
    }

    const doSearch = () => {
      pagination.current = 1;
      updateParams()
      getTableData(currentState)
    };
    // const resetSearch = () => {
    //   updateParams()
    //   getTableData(currentState)
    // };

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    const columns = [
      {
        title: "邮件类型",
        dataIndex: "emailType",
        key: "emailType",
        ellipsis: true,
        width: 200,
      },
      {
        title: "邮件标题",
        dataIndex: "emailName",
        key: "emailName",
        ellipsis: true,
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        slots: { customRender: "action" },
        width: 120,
        align: "center",
        fixed: "right",
      },
    ]

    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getTableData(currentState);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        getTableData(currentState);
      },
    });

    const getTableData = (filterData) => {
      const params = {
        ...filterData,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.getEmailInfo(params).then((res) => {
        pagination.total = res.data.total;
        tableData.value = res.data.content;
      });
    };
    const getOptions = () => {
      $api.getEmailType().then((res) => {
        typeOps.value = dictionaryOpt(res.data);
      });
    }

    const infos = ref({})
    const configVisible = ref(false)
    const configClick = (record) => {
      infos.value = record
      configVisible.value = true
    }
    const closeModal = () => {
      configVisible.value = false
    }

    const checkClick = (record) => {
      const params = {
        id:record.id,
        check: record.isCheck === 1?'0':'1' ,
      }
      $api.checkOrDisable(params).then((res) => {
        if(res.code === 200){
          let msg = record.isCheck === 1? '配置RM列表成功！' : '已取消显示RM列表'
          message.success(msg);
          modelValue.value = false
          doSearch();
        }
      });
    }

    const deleteRecord = ref("")
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    // const deactivateClick = (record) => {
    //   deleteRecord.value = record
    //   content.value = '停用后将不再发送该邮件，是否继续？'
    //   showIconCom.value = ''
    //   modelValue.value = true
    // }
    const handleOk = () => {
      const params = {
        id:deleteRecord.value.id,
        enable:'1',
      }
      $api.checkOrDisable(params).then((res) => {
        if(res.code === 200){
          message.success(res.msg);
          modelValue.value = false
          doSearch();
        }
      });
    }

    onMounted(() => {
      doSearch();
      getSize();
      getOptions()
    });
    return {
      formState,
      formRef,
      resetForm,
      doSearch,
      tableHeight,
      getSize,
      columns,
      tableData,
      pagination,
      getTableData,
      typeOps,
      configClick,
      checkClick,
      getOptions,
      modelValue,
      content,
      showIconCom,
      deleteRecord,
      handleOk,
      configVisible,
      closeModal,
      infos,
    };
  }
})
</script>

<style scoped lang="less">
.icon {
  font-size: 24px;
  color: #3182CE;
  cursor: pointer;
}
.redIcon {
  color: #DC5E4F;
}
.img {
  width: 1.3vw;
  height: 1.3vw;
  margin-top: -0.4vw;
  cursor: pointer;
}
</style>
