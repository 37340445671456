<template>
  <div ref="rmListModalRef">
    <a-modal
        :getContainer="() => $refs.rmListModalRef"
        :visible="visible"
        @cancel="closeModal"
        @ok="submitModal"
        title="请填写人员信息"
        width="20.8vw"
        centered
    >
      <template #footer>
        <div class="footerBtn">
          <a-button @click="closeModal">取消</a-button>
          <a-button @click="submitModal" type="primary">确定</a-button>
        </div>
      </template>
      <a-form
          layout="vertical"
          :model="formData"
          :rules="formRules"
          ref="formRef"
      >
        <a-form-item label="Org" name="org">
          <a-input v-model:value="formData.org" placeholder="请输入" allowClear :maxlength="30" @keyup.enter="(e) => { e.stopPropagation() }"/>
        </a-form-item>
        <a-form-item label="Team" name="team">
          <a-input v-model:value="formData.team" placeholder="请输入" allowClear :maxlength="100" @keyup.enter="(e) => { e.stopPropagation() }"/>
        </a-form-item>
        <a-form-item label="Resource Manager" name="rm" class="form-item">
          <a-select
              :dropdownStyle="{maxHeight: '20vh',}"
              :getPopupContainer="triggerNode => triggerNode.parentNode"
              placeholder="请输入姓名/GGID"
              autocomplete="off"
              v-model:value="formData.rm"
              :options="resourceManagerOpts"
              :filter-option="filterOption"
              showSearch
              allowClear
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent, onMounted, ref, watch} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {message} from "ant-design-vue";

export default defineComponent({
  name: "RmListModal",
  props: {
    visible: Boolean,
    formState: {
      type: Object,
      default: () => ({
        id:""
      })
    },
  },
  emits: ["close-modal", "save-modal"],
  components: {},
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const formData = ref({
      org:"",
      team:"",
      rm:undefined,
      id:"",
    })
    const formRef = ref();
    const formRules = {
      org: [
        {
          required: true,
          message: "Please input org",
          trigger: "blur",
        },
      ],
      team: [
        {
          required: true,
          message: "Please input team",
          trigger: "blur",
        },
      ],
      rm: [
        {
          required: true,
          message: "Please input Resource Manager",
          trigger: "blur",
        },
      ],
    };
    const resourceManagerOpts = ref([])
    const getResourceManagerOpts = () => {
      $api.getCurrentRm().then((res) => {
        resourceManagerOpts.value = res.data.map(item=>{
          return {
            ...item,
            value: item.nameAndEmail
          }
        })
      });
    }
    const filterOption = (input, option) => {
      if (!input) return false;
      let reg = /[\s|/,|/，]*/g
      return option.value.replace(reg,'').toUpperCase().indexOf(input.replace(reg,'').toUpperCase()) >= 0||option.ggid.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    };

    watch(
        () => props.visible,
        (val) => {
          if (val) {
            if(props.formState.id){
              formData.value = Object.assign({}, formData.value, props.formState)
              formData.value.rm = resourceManagerOpts.value.filter(item => item.rmId === formData.value.rmId)[0]?.nameAndEmail
            }
          }
        }
    );
    const closeModal = () => {
      formRef.value.resetFields();
      formData.value = Object.assign({})
      emit("close-modal");
    };

    const submitModal = () => {
      formRef.value.validate().then(() => {
        const params = {
          org:formData.value.org,
          team:formData.value.team,
          rmId:resourceManagerOpts.value.filter(item => item.nameAndEmail === formData.value.rm)[0]?.rmId,
          id:formData.value.id? formData.value.id : "",
        }
        $api.saveRmList(params).then((res) => {
          if(res.code === 200){
            formRef.value.resetFields();
            formData.value = Object.assign({})
            message.success(res.msg);
            emit("save-modal");
          }
        });
      });
    };
    onMounted(() => {
      getResourceManagerOpts()
    })

    return {
      closeModal,
      submitModal,
      formData,
      formRef,
      formRules,
      resourceManagerOpts,
      getResourceManagerOpts,
      filterOption,
    };
  },
});
</script>

<style scoped lang="less">

</style>
