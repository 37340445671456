<template>
  <div ref="configModalRef">
    <a-modal
        :getContainer="() => $refs.configModalRef"
        :visible="visible"
        @cancel="closeModal"
        @ok="submitModal"
        title="请设置延期提醒邮件触发时间"
        width="40.6vw"
        centered
    >
      <template #footer>
        <div class="footerBtn">
          <a-button @click="closeModal">取消</a-button>
          <a-button @click="submitModal" type="primary">确定</a-button>
        </div>
      </template>
      <div class="infos">
        <a-form ref="formRef" :model="formData">
        <div v-for="(item,index) in formData" :key="item.id" class="info">
          <a-row>
            <a-col :span="3">
              <span style="color: #4D4D4D99;">第{{item.sort}}封：</span>
            </a-col>
            <a-col :span="2">
              <span>Send</span>
            </a-col>
            <a-col :span="10">
              <a-form-item :name="[index, 'days']" :rules="[
                               {
                                pattern: /^-1[0-5]$|^[-+]?[0-9]$|^4[0-5]$|^[0-3][0-9]$/,
                                message: '*格式不正确，请输入-15~45之内的天数',
                                trigger: 'blur',
                                },
                           ]"
              >
                <a-input
                    :value="formData[index].days"
                    @update:value="(val) => (formData[index].days = val)"
                    placeholder="请输入天数"
                    style="width: 90%"
                />
              </a-form-item>
            </a-col>
            <a-col :span="9">
              <span>Days Before Estimate Release Date</span>
            </a-col>
          </a-row>
        </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent, ref, watch} from "vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {message} from "ant-design-vue";

export default defineComponent({
  name: "ConfigModal",
  props: {
    visible: Boolean,
    infos: {
      type: Object,
      default: () => ({
        id: ""
      })
    },
  },
  emits: ["close-modal"],
  components: {},
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const formData = ref([])
    const formRef = ref();
    const getConfigData = () => {
      const params = {
        emailManagementId: props.infos.id
      }
      $api.getEmailManagementId(params).then((res) => {
        formData.value = res.data;
      });
    }

    watch(
        () => props.visible,
        (val) => {
          if (val) {
            getConfigData()
          }
        }
    );
    const closeModal = () => {
      formRef.value.resetFields();
      emit("close-modal");
    };

    const submitModal = () => {
      formData.value = formData.value.map(item => {
        return {
          ...item,
          days:item.days? item.days : null
        }
      })
      formRef.value.validate().then(() => {
        $api.updateSendMailDaysById(formData.value).then((res) => {
          if(res.code === 200) {
            message.success(res.msg)
            emit("close-modal")
          }
        });
      })
    };

    return {
      closeModal,
      submitModal,
      formData,
      getConfigData,
      formRef,
    };
  },
});
</script>

<style scoped lang="less">
.infos {
  background: #FCFCFC;
  padding: 16px 12px 0;
  .info {
    font-size: 16px;
    font-weight: 400;
  }
}
</style>
