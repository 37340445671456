<template>
  <section class="content-email">
    <div>
      <a-tabs v-model:value="activeTab" @change="tabChange">
        <a-tab-pane v-for="{ name, title } in tabList" :key="name" :tab="title"></a-tab-pane>
      </a-tabs>
    </div>
    <div class="app-container">
      <component :is="activeTab" :ref="tabRef" />
    </div>
  </section>
</template>

<script>
import {defineComponent, onActivated, ref} from "vue";
import EmailManagementTab from "@/views/home/ConfigManagement/EmailManagement/EmailManagementTab/index.vue";
import RmManagementTab from "@/views/home/ConfigManagement/EmailManagement/RmManagementTab/index.vue";

export default defineComponent({
  name: "index",
  components: {
    EmailManagementTab,
    RmManagementTab,
  },
  setup(props, ctx) {
    const activeTab = ref("EmailManagementTab");
    const tabRef = ref();

    const tabChange = (value) => {
      activeTab.value = value
    };

    const tabList = ref([
      {
        name: "EmailManagementTab",
        title: "邮件管理",
      },
      {
        name: "RmManagementTab",
        title: "RM列表",
      },
    ]);

    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      tabList,
      tabRef,
      activeTab,
      tabChange,
    };
  },
});
</script>

<style scoped lang="less">
@import "~@/style/newTitleTab.less";
.content-email{
  height: calc(100% - 40px);
}
</style>
